<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="$route.name === 'index'" class="pa-2">
      <v-card color="#E3F2FD">
        <v-card-text
          class="w-block__body2"
          :class="{
            'pa-3': $voicer.isMobile === false,
            'pa-2': $voicer.isMobile === true,
            Subtitle2: $voicer.isMobile,
            Subtitle2: $voicer.isMobile === false,
          }"
        >
          <strong>
            Radio Papilles lance son concours « Sodexo et Sogeres ont un
            incroyable talent !
          </strong>
          <br />
          Si vous souhaitez participer et gagner un robot pâtissier de pro,
          enregistrez votre incroyable talent jusqu’au 8 novembre en version
          audio en cliquant juste ici :
          <br />
          <v-btn color="primary" @click.stop="showFeedbackModal = true">
            Enregistrez votre talent !
          </v-btn>
          <w-feedback-popup id="firstFeedback" v-model="showFeedbackModal" />
        </v-card-text>
      </v-card>
    </v-container>
    <exc-dialog />
  </div>
</template>
<script>
import WFeedbackPopup from '../components/WFeedbackPopup'
import ExcDialog from '~/excentrics/components/ExcDialog'
export default {
  components: {
    ExcDialog,
    WFeedbackPopup,
  },
  data() {
    return {
      showFeedbackModal: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.v-sheet {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
  cursor: pointer;
}
.v-btn {
  border-radius: 4px;
  margin-bottom: 1px;
}
</style>
